import { FunctionComponent, HTMLAttributes, ReactNode } from "react";
import styles from "./infoCard.module.css";

export interface InfoCardProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {
  title?: string | ReactNode;
  icon?: string;
}

export const InfoCard: FunctionComponent<InfoCardProps> = ({
  title,
  children,
  icon,
}) => {
  return (
    <div className={styles.infoCard}>
      {title ? (
        <h3 className={`${styles.infoCard__title} h2 h2_noMargin`}>{title}</h3>
      ) : null}
      <div className={`${styles.infoCard__content} text`}>{children}</div>
      {icon ? (
        <img className={styles.infoCard__icon} src={icon} alt="" />
      ) : null}
    </div>
  );
};
