import { FunctionComponent } from "react";
import {CardGrid, CardGridItem} from "../../../components/layout/cardGrid";
import { InfoCard } from "../../../components/cards/infoCard";
import angularIcon from "../../../images/abstract/angular.svg";
import roundIcon from "../../../images/abstract/round.svg";
import flowIcon from "../../../images/abstract/flow.svg";
import { Section, SectionTitle } from "../../../components/layout/section";
import { Translation } from "../../../translations/translation";

export const LegalSection: FunctionComponent = () => {
  return (
    <Section
      title={
        <SectionTitle>
          <Translation k={"License and eligibility"} />
        </SectionTitle>
      }
    >
      <CardGrid>
        <CardGridItem>
          <InfoCard
            title={<Translation k={"We comply with all legal requirements"} />}
            icon={angularIcon}
          >
            <p>
              <Translation
                k={
                  "We have an office and legal registration in Estonia. You can find oud address in contact page"
                }
              />
            </p>
          </InfoCard>
        </CardGridItem>
        <CardGridItem>
          <InfoCard
            title={<Translation k={"Available worldwide and flexible"} />}
            icon={roundIcon}
          >
            <p>
              <Translation
                k={
                  "We work all over the world. The most popular payment methods are available, such as Master card, Visa, Sepa, Skrill, Neteller"
                }
              />
            </p>
          </InfoCard>
        </CardGridItem>
        <CardGridItem>
          <InfoCard
            title={<Translation k={"We will provide fast support"} />}
            icon={flowIcon}
          >
            <p>
              <Translation
                k={
                  "Quick response to your letter via email or online chat. The operator will always be happy to help"
                }
              />
            </p>
          </InfoCard>
        </CardGridItem>
      </CardGrid>
    </Section>
  );
};
