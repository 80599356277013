export const useFormattedPrice = (price: {
  value: number;
  currency: string;
}): string | undefined => {
  if (!price) return undefined;
  try {
    let formattedPrice = new Intl.NumberFormat("en", {
      style: "currency",
      currency: price.currency,
    }).format(price.value);
    return formattedPrice;
  } catch (e) {
    return price.value.toString();
  }
};
