import { TitleDropdown } from 'components/common/titleDropdown';
import { FunctionComponent, useEffect, useState } from 'react';
import { PageHeader } from '../../components/layout/pageHeader';
import { Container } from '../../components/layout/container';
import { PriceTable } from '../../components/priceTable';
import { Main } from '../../components/layout/main';
import { useLanguage } from '../../store/slices/common/selectors';
import { Translation } from '../../translations/translation';
import { useConfigData } from 'hooks/useConfigData';

export const Prices: FunctionComponent = () => {
  const { data } = useConfigData();
  const [selected, setSelected] = useState<string | null>(null);
  const lang = useLanguage();

  useEffect(() => {
    if (data) {
      setSelected(data.pricesFiatList[0]);
    }
  }, [data]);

  if (!data || !selected || !lang) {
    return null;
  }

  const currencies = data.pricesFiatList;

  return (
    <Main>
      <PageHeader
        subtitle={<Translation k="Prices" />}
        title={
          <h1 className={`h1 h1_noMargin`}>
            <Translation k={'Cryptocurrency'} /> <Translation k={'for'} />{' '}
            <TitleDropdown
              options={currencies.map((currency) => {
                return {
                  value: currency,
                  label: currency,
                };
              })}
              value={selected}
              onChange={(value) => setSelected(value)}
            />
          </h1>
        }
      />
      <Container>
        <PriceTable lang={lang} fiat={selected} />
      </Container>
    </Main>
  );
};
