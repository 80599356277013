import { Section, SectionTitle } from '../../../components/layout/section';
import { Translation } from '../../../translations/translation';
import { TitleDropdown } from '../../../components/common/titleDropdown';
import { Container } from '../../../components/layout/container';
import { PriceTable } from '../../../components/priceTable';
import { FunctionComponent, useEffect, useState } from 'react';
import { useLanguage } from '../../../store/slices/common/selectors';
import { useConfigData } from 'hooks/useConfigData';

export const PricesSection: FunctionComponent = () => {
  const { data } = useConfigData();
  const [selected, setSelected] = useState<string | null>(null);
  const lang = useLanguage();

  useEffect(() => {
    if (data) {
      setSelected(data.pricesFiatList[0]);
    }
  }, [data]);

  if (!data || !selected || !lang) {
    return null;
  }

  const currencies = data.pricesFiatList;

  return (
    <Section
      inverted
      title={
        <SectionTitle>
          <Translation k={'Cryptocurrency'} />
          <br />
          <Translation k={'for'} />{' '}
          <TitleDropdown
            options={currencies.map((currency) => {
              return {
                value: currency,
                label: currency,
              };
            })}
            value={selected}
            onChange={(value) => setSelected(value)}
          />
        </SectionTitle>
      }
    >
      <Container>
        <PriceTable fiat={selected} lang={lang} main />
      </Container>
    </Section>
  );
};
