// import { useDictionary } from '../store/slices/dictionary/selectors';

import dictionary from './dictionary.json';

export type DictionaryType = Record<string, string | number>;

export const useTranslator = () => {
  // const dictionary = useDictionary();

  return (key: string, replaceDictionary?: DictionaryType) => {
    if (!dictionary) return key;

    let translation = (dictionary as unknown as any)[key] || key;
    if (replaceDictionary) {
      Object.keys(replaceDictionary).forEach((key) => {
        translation = translation.replaceAll(
          `{${key}}`,
          `${replaceDictionary[key]}`
        );
      });
    }

    return translation;
  };
};

export const useTranslation = (
  key: string,
  replaceDictionary?: DictionaryType
) => {
  return useTranslator()(key, replaceDictionary);
};
