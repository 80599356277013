import { FunctionComponent } from 'react';
import { PageHeader } from '../../components/layout/pageHeader';
import { TextGrid } from '../../components/layout/textGrid';
import { MarkdownParser } from '../../components/layout/textBlock';
import { Container } from '../../components/layout/container';
import { Main } from '../../components/layout/main';
// import { useLanguage } from "../../store/slices/common/selectors";
import { Loader } from '../../components/common/loader';
// import { useGetTermsQuery } from "../../store/api/terms";
import { TermsSection } from './termsSection';
import styles from './terms.module.css';
import { Link, useParams } from 'react-router-dom';
import { Error } from '../error';

import data from '../../translations/dictionary.json';

export const Terms: FunctionComponent = () => {
  // const lang = useLanguage();
  // const { data } = useGetTermsQuery({ lang: lang || "en" });
  const { slug } = useParams();

  if (!data || !slug) {
    return (
      <Main>
        <Loader />
      </Main>
    );
  }

  const section = data['terms-sections'].find((section) =>
    section.slug.includes(slug)
  );

  if (!section) {
    return <Error code={404} />;
  }

  return (
    <Main>
      <PageHeader subtitle={data['terms-subtitle']} title={section.title}>
        <MarkdownParser text={data['terms-titleContent']} />
      </PageHeader>
      <Container>
        <TextGrid
          aside={
            <ol className={styles.terms__nav}>
              {data['terms-sections'].map((section, index) => (
                <li
                  key={index}
                  className={`${styles.terms__navItem} ${
                    section.slug.includes(slug)
                      ? styles.terms__navItem_active
                      : ''
                  }`}
                >
                  <Link
                    className={`${styles.terms__navLink}`}
                    to={section.slug}
                  >
                    {section.title}
                  </Link>
                </li>
              ))}
            </ol>
          }
        >
          <TermsSection section={section} index={0} />
        </TextGrid>
      </Container>
    </Main>
  );
};
