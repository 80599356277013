import { AnchorHTMLAttributes, FunctionComponent } from "react";
import { LinkProps, Link } from "react-router-dom";
import styles from "./footerMenuLink.module.css";

export type FooterMenuLinkProps =
  | LinkProps
  | AnchorHTMLAttributes<HTMLAnchorElement>;

export const FooterMenuLink: FunctionComponent<FooterMenuLinkProps> = ({
  children,
  ...rest
}) => {
  if ("href" in rest) {
    return (
      <a className={styles.footerMenuLink} {...rest}>
        {children}
      </a>
    );
  }
  return (
    // @ts-ignore
    <Link className={styles.footerMenuLink} {...rest}>
      <span>{children}</span>
    </Link>
  );
};
