import React, { FunctionComponent, useEffect, useState } from 'react';
import { Container } from 'components/layout/container';
import styles from './nav.module.css';
import { Link } from 'react-router-dom';
import logo from 'images/logo.svg';
import { useTranslation } from 'translations/useTranslation';
import { AnchorButton, Button, LinkButton } from 'components/common/button';
import { Translation } from 'translations/translation';
import { LanguageDropdown } from '../../common/langDropdown';
import { useLanguage } from '../../../store/slices/common/selectors';
import { useAppDispatch } from '../../../store';
import { commonSlice } from '../../../store/slices/common';
import { ConfigType } from 'store/api/config/types';
import { useConfigData } from 'hooks/useConfigData';

export const Nav: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const title = useTranslation('title');
  const [open, setOpen] = useState(false);
  const { data } = useConfigData();
  const language = useLanguage();

  const [section, setSection] = useState<ConfigType | undefined>(undefined);

  useEffect(() => {
    if (data) {
      const obj: ConfigType = {
        ...data,
        headerSections: {
          ...data.headerSections,
          auth: [
            {
              key: 'header:section:sign_in',
              path: 'https://client.noronex.com',
              target: '_self',
            },
            {
              key: 'header:section:sign_up',
              path: 'https://client.noronex.com/registration',
              target: '_self',
            },
          ],
        },
      };

      setSection(obj);
    }
  }, [data]);

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setOpen(false);
      }
    };
    document.addEventListener('keydown', handler);
    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, [open]);

  const handleClick = () => {
    setOpen(false);
  };

  if (!data) return null;

  return (
    <>
      {section && (
        <>
          <nav className={styles.nav}>
            <Container>
              <div className={styles.nav__container}>
                <div className={styles.nav__wing}>
                  <Link
                    className={styles.nav__logo}
                    to={'/'}
                    onClick={handleClick}
                  >
                    <img src={logo} alt={title} />
                  </Link>
                </div>
                <div className={styles.nav__wing}>
                  <div className={styles.nav__desktopMenu}>
                    <div className={styles.nav__nav}>
                      {section.headerSections.menu.map((button, index) => {
                        if (
                          button.path.includes('://') &&
                          button.path[0] !== '/'
                        ) {
                          return (
                            <AnchorButton
                              href={button.path}
                              onClick={handleClick}
                              ghost
                              target={button.target}
                              rel={
                                button.target === '_blank'
                                  ? 'noopener noreferrer'
                                  : undefined
                              }
                              key={index}
                            >
                              <Translation k={button.key} />
                            </AnchorButton>
                          );
                        }
                        return (
                          <LinkButton
                            to={button.path}
                            onClick={handleClick}
                            ghost
                            key={index}
                          >
                            <Translation k={button.key} />
                          </LinkButton>
                        );
                      })}
                    </div>
                    {/* {section.headerSections.auth.map((button, index) => {
                  if (button.path.includes("://") && button.path[0] !== "/") {
                    return (
                      <AnchorButton
                        href={button.path}
                        onClick={handleClick}
                        outline={index === 0}
                        target={button.target}
                        rel={
                          button.target === "_blank"
                            ? "noopener noreferrer"
                            : undefined
                        }
                        key={index}
                      >
                        <Translation k={button.key} />
                      </AnchorButton>
                    );
                  }
                  return (
                    <AnchorButton
                      href={button.path}
                      outline={index === 0}
                      onClick={handleClick}
                      key={index}
                    >
                      <Translation k={button.key} />
                    </AnchorButton>
                  );
                })} */}

                    {section.headerSections.auth.map((button, index) => {
                      if (
                        button.path.includes('://') &&
                        button.path[0] !== '/'
                      ) {
                        return (
                          <AnchorButton
                            href={button.path}
                            onClick={handleClick}
                            outline={index === 0}
                            target={button.target}
                            rel={
                              button.target === '_blank'
                                ? 'noopener noreferrer'
                                : undefined
                            }
                            key={index}
                          >
                            <Translation k={button.key} />
                          </AnchorButton>
                        );
                      }
                      return (
                        <AnchorButton
                          href={button.path}
                          outline={index === 0}
                          onClick={handleClick}
                          key={index}
                        >
                          <Translation k={button.key} />
                        </AnchorButton>
                      );
                    })}
                    {language ? (
                      <LanguageDropdown
                        options={section.availableLanguages.map((lang) => {
                          return {
                            value: lang,
                            label: (
                              <Translation k={`language:${lang}`} key={lang} />
                            ),
                          };
                        })}
                        value={language}
                        onSelect={(value) => {
                          dispatch(commonSlice.actions.setLanguage(value));
                        }}
                      />
                    ) : null}
                  </div>
                  <div className={styles.nav__mobileMenu}>
                    <Button iconOnly ghost onClick={() => setOpen(true)}>
                      <svg
                        width={26}
                        height={12}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <use href="#mobileMenu" />
                      </svg>
                    </Button>
                  </div>
                </div>
              </div>
            </Container>
          </nav>
          <div
            className={`${styles.nav__modal} ${
              open ? styles.nav__modal_open : ''
            }`}
          >
            <Container>
              <div className={styles.nav__container}>
                <div className={styles.nav__wing}>
                  <Link
                    className={styles.nav__logo}
                    to={'/'}
                    onClick={handleClick}
                  >
                    <img src={logo} alt={title} />
                  </Link>
                </div>
                <div className={styles.nav__wing}>
                  {language ? (
                    <LanguageDropdown
                      options={section.availableLanguages.map((lang) => {
                        return {
                          value: lang,
                          label: (
                            <Translation k={`language:${lang}`} key={lang} />
                          ),
                        };
                      })}
                      value={language}
                      onSelect={(value) => {
                        dispatch(commonSlice.actions.setLanguage(value));
                      }}
                    />
                  ) : null}
                  <Button iconOnly ghost onClick={() => setOpen(false)}>
                    <svg
                      width={24}
                      height={24}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <use href="#mobileMenuClose" />
                    </svg>
                  </Button>
                </div>
              </div>
            </Container>
            <div className={styles.nav__modalWrapper}>
              <div className={styles.nav__modalContent}>
                <Container>
                  <ul className={styles.nav__modalNav}>
                    {section.headerSections.menu.map((button, index) => {
                      if (
                        button.path.includes('://') &&
                        button.path[0] !== '/'
                      ) {
                        return (
                          <li key={index}>
                            <a
                              href={button.path}
                              target={button.target}
                              rel={
                                button.target === '_blank'
                                  ? 'noopener noreferrer'
                                  : undefined
                              }
                              onClick={handleClick}
                            >
                              <Translation k={button.key} />
                            </a>
                          </li>
                        );
                      }
                      return (
                        <li key={index}>
                          <Link to={button.path} onClick={handleClick}>
                            <Translation k={button.key} />
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </Container>
              </div>
              <div className={styles.nav__modalFooter}>
                <Container>
                  <div className={styles.nav__modalFooterNav}>
                    {section.headerSections.auth
                      .slice(0)
                      .reverse()
                      .map((button, index) => {
                        return (
                          <AnchorButton
                            href={button.path}
                            outline
                            outlineColor={index === 0}
                            target={button.target}
                            rel={
                              button.target === '_blank'
                                ? 'noopener noreferrer'
                                : undefined
                            }
                            key={index}
                          >
                            <Translation k={button.key} />
                          </AnchorButton>
                        );
                      })}
                  </div>
                </Container>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
