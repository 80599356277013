import React, {
  FunctionComponent,
  useEffect,
  KeyboardEvent,
  useState,
} from 'react';
import styles from './calculator.module.css';
import { BigButtonLink } from '../../../components/common/bigButton';
import { CalculatorDropdown } from './calculatorDropdown';
import { useAppDispatch, useAppSelector } from '../../../store';
import { PaymentDropdown } from './paymentDropdown';
import { Translation } from '../../../translations/translation';
import { Loader } from '../../../components/common/loader';
import { calculationThunk, getCourseThunk } from 'store/slices/exchange';
import {
  balancesSelector,
  coursesSelector,
  cryptocurrenciesSelector,
  fiatsSelector,
  limitsSelector,
  paymentsSelector,
  typesSelector,
} from 'store/slices/exchange/selectors';
import {
  ExchangeType,
  ICourseObg,
  ILimit,
  IPaymentMethod,
  IRequestCalculation,
  ISelectOption,
} from 'store/slices/exchange/types';
import _debounce from 'lodash/debounce';
import { removeEmpty } from 'utils/removeEmpty';
import { Controller, useForm } from 'react-hook-form';

export const Calculator: FunctionComponent = () => {
  const dispatch = useAppDispatch();

  const courses = useAppSelector(coursesSelector);
  const cryptocurrencies = useAppSelector(cryptocurrenciesSelector);
  const fiats = useAppSelector(fiatsSelector);
  const balances = useAppSelector(balancesSelector);
  const limits = useAppSelector(limitsSelector);
  const payments = useAppSelector(paymentsSelector);
  const types = useAppSelector(typesSelector);

  const [isLoading, setLoading] = useState<boolean>(true);
  const [action, setAction] = useState<ExchangeType>('buy');
  const [calculate, setCalculate] = useState<'from' | 'to' | null>(null);
  const [limit, setLimit] = useState<ILimit | null>(null);
  const [balance, setBalance] = useState<string>('');
  const [change, setChange] = useState<string>('');
  const [course, setCourse] = useState<string>('');
  const [direction, setDirection] = useState<string>('');
  const [fiat, setFiat] = useState<ICourseObg | null>(null);
  const [currency, setCurrency] = useState<ICourseObg | null>(null);
  const [currentMethods, setCurrentMethods] = useState<IPaymentMethod[]>([]);
  const [currentMethod, setCurrentMethod] = useState<IPaymentMethod | null>(
    null
  );
  const [currencyList, setCurrencyList] = useState<ISelectOption[]>([]);
  const [fiatList, setFiatList] = useState<ISelectOption[]>([]);
  const [crypto, setCrypto] = useState<{ from: string; to: string }>({
    from: '',
    to: '',
  });

  const getCourse = async () => {
    try {
      await dispatch(getCourseThunk()).unwrap();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCourse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (balances) {
      const b = balances[direction];

      if (b) {
        setBalance(b);
      }
    }
  }, [balances, direction]);

  useEffect(() => {
    if (limits) {
      const l = changeLimit(direction);

      if (!limit && l) {
        setValue('from', String(l.min));
      }

      if (l) {
        setLimit(l);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limits, direction]);

  useEffect(() => {
    if (direction && payments.length) {
      const el = payments.find((el) => el.direction === direction);

      if (el) {
        setCurrentMethods(el.methods);

        if (el.methods.length) {
          setCurrentMethod(el.methods[0]);
        }
      } else {
        setCurrentMethods([]);
        setCurrentMethod(null);
      }
    }
  }, [direction, payments]);

  useEffect(() => {
    if (currentMethod) {
      setValue('payment_id', currentMethod.id);

      const values = getValues();

      console.log('values', values);
      console.log('change', change);

      if (values.from && (!change || change === 'from')) {
        setCalculate('to');
        calculation({
          ...values,
          to: '',
        });
      } else {
        setCalculate('from');
        calculation({
          ...values,
          from: '',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMethod]);

  useEffect(() => {
    if (fiats.length && cryptocurrencies.length) {
      const f = fiats[0];
      setFiat(f);
      const c = cryptocurrencies[0];
      setCurrency(c);

      setCrypto({ from: f.label, to: c.label });

      const str = `${f.label}/${c.label}`;
      setDirection(str);
      setValue('direction', str);
      setCrypto({ from: f.label, to: c.label });

      const fList = fiats.map((el) => {
        return {
          value: el.label,
          label: el.label,
        };
      });

      const cList = cryptocurrencies.map((el) => {
        return {
          value: el.label,
          label: el.label,
        };
      });

      setCurrencyList(cList);
      setFiatList(fList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fiats, cryptocurrencies]);

  useEffect(() => {
    if (direction && courses && action) {
      const course = courses.find((el) => el.method === action);

      if (course) {
        const el = course.data.find((el) => el.direction === direction);

        if (el && currentMethod) {
          const current = el.list.find((item) => item.id === currentMethod.id);

          if (current) {
            if (action === 'buy') {
              const transformNumber = String(current.course).match(
                /^\d+[,|.]\d{5}/g
              );

              if (transformNumber) {
                setCourse(`${transformNumber[0]}`);
              }
            } else {
              setCourse(current.course);
            }
          }
        } else {
          setCourse('');
        }
      }
    } else {
      setCourse('');
    }
  }, [direction, courses, action, currentMethod]);

  const { register, control, setValue, watch, getValues } =
    useForm<IRequestCalculation>({
      defaultValues: new IRequestCalculation(),
      mode: 'onChange',
    });

  const onSelect = (select: string, value: string) => {
    const d = { ...crypto, [select]: value };

    setCrypto(d);

    const str = `${d.from}/${d.to}`;
    setDirection(str);
    setValue('direction', str);

    if (select === 'from') {
      const el = fiats.find((el) => el.label === value);

      if (el) {
        setFiat(el);
      }
    } else {
      const el = cryptocurrencies.find((el) => el.label === value);

      if (el) {
        setCurrency(el);
      }
    }
  };

  const handleKeyDown = async (e: KeyboardEvent<HTMLInputElement>) => {
    const regex = new RegExp(/\d|\./);
    const val = e.key;

    if (val !== 'Backspace' && !regex.test(val)) e.preventDefault();
  };

  const changeLimit = (direction: string): ILimit | null => {
    const l = limits.find((el) => el.currency === direction);

    if (l) {
      return l;
    }

    return null;
  };

  const changeAction = (a: ExchangeType) => {
    setAction(a);

    if (fiat && currency) {
      let direction: string = '';

      if (a === 'buy') {
        direction = `${fiat.label}/${currency.label}`;
        setCrypto({ from: fiat.label, to: currency.label });
      } else {
        direction = `${currency.label}/${fiat.label}`;
        setCrypto({ from: currency.label, to: fiat.label });
      }

      setDirection(direction);
      setValue('direction', direction);

      const l = changeLimit(direction);

      if (l) {
        setValue('from', String(l.min));
        setValue('to', '');
        setChange('from');
        setCalculate('to');
      }
    }
  };

  const calculation = _debounce(async (form: IRequestCalculation) => {
    const data = removeEmpty(form);

    try {
      const { from, to } = await dispatch(calculationThunk(data)).unwrap();

      if (from && to) {
        setValue('to', to);
        setValue('from', from);
      }

      setCalculate(null);
    } catch (errors) {
      console.log(errors);
      setCalculate(null);
    }
  }, 1000);

  useEffect(() => {
    const subscription = watch((form, { type, name }) => {
      // console.log('subscription', form);
      // console.log('name', name);

      // if (form.from && form.payment_id !== 0 && !type && !form.to && !change) {
      //   setCalculate('to');
      //   calculation(form as IRequestCalculation);
      // }

      if (type === 'change') {
        if (name === 'from' && form.from) {
          setCalculate('to');
          calculation({ ...form, to: '' } as IRequestCalculation);
        } else if (name === 'from' && !form.from) {
          setValue('to', '');
        }

        if (name === 'to' && form.to) {
          setCalculate('from');
          calculation({ ...form, from: '' } as IRequestCalculation);
        } else if (name === 'to' && !form.to) {
          setValue('from', '');
        }

        if (name === 'payment_id' && (form.from || form.to)) {
          calculation(form as IRequestCalculation);
        }
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, change]);

  return (
    <>
      {isLoading ? (
        <div className={styles.loading}>
          <Loader narrow />
        </div>
      ) : (
        <div className={styles.calculator}>
          <div className={styles.calculator__tabs}>
            {types.map((el) => (
              <div key={el.id} className={styles.calculator__tab}>
                <input
                  type="radio"
                  name="mode"
                  id={el.label}
                  value={el.label}
                  checked={action === el.label.toLowerCase()}
                  onChange={() =>
                    changeAction(el.label.toLowerCase() as ExchangeType)
                  }
                />
                <label htmlFor={el.label}>
                  <span className={styles.calculator__tabLabel}>
                    {el.label}
                  </span>
                </label>
              </div>
            ))}
          </div>
          <div className={styles.calculator__body}>
            <div className={styles.calculator__grid}>
              <div className={styles.calculator__card}>
                <div className={styles.calculator__cardHeader}>
                  <div className={styles.calculator__cardTitle}>
                    <Translation k={`calculator:input:label`} />
                  </div>

                  {currentMethod && (
                    <Controller
                      name="payment_id"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <PaymentDropdown
                          options={currentMethods.map((el) => {
                            return {
                              value: el.id,
                              label: el.type.toLowerCase(),
                            };
                          })}
                          value={value}
                          onSelect={(v) => {
                            onChange(v);

                            const current = currentMethods.find(
                              (el) => el.id === v
                            );
                            if (current) setCurrentMethod(current);
                          }}
                        />
                      )}
                    />
                  )}

                  {/* {selectedOperation === Operations.buy ? (
                <PaymentDropdown
                  options={paymentMethods.map((method) => ({
                    label: method,
                    value: method,
                  }))}
                  value={paymentMethod}
                  onSelect={(value) =>
                    dispatch(calculatorSlice.actions.setPaymentMethod(value))
                  }
                />
              ) : rate && !isFetching ? (
                <div className={styles.calculator__cardLabel}>
                  <Translation
                    k={`Available: {sum} {coin}`}
                    replaceDictionary={{
                      coin: rate.available.currency,
                      sum: rate.available.sum,
                    }}
                  />
                </div>
              ) : null} */}
                </div>
                <div className={styles.calculator__cardForm}>
                  <CalculatorDropdown
                    options={action === 'buy' ? fiatList : currencyList}
                    value={crypto['from']}
                    onSelect={(value) => onSelect('from', value)}
                  />

                  <div className={styles.calculator__cardValue}>
                    {calculate === 'from' ? (
                      <Loader narrow />
                    ) : (
                      <input
                        type="text"
                        // type={'number'}
                        // min={limit?.min}
                        // max={limit?.max}
                        // value={values['from']}
                        // onChange={(e) => onChange('from', e.target.value)}
                        {...register('from')}
                        className={styles.calculator__input}
                        tabIndex={1}
                        onKeyDown={handleKeyDown}
                      />
                    )}
                  </div>
                </div>

                {limit && (
                  <div className={styles.calculator__cardDescription}>
                    <Translation
                      k={`Min: {minSum} {fiat} Max: {maxSum} {fiat}`}
                      replaceDictionary={{
                        minSum: limit.min,
                        maxSum: limit.max,
                        fiat: crypto['from'],
                      }}
                    />
                    {/* {rate && !isFetching ? (
                  <>
                    {selectedOperation === Operations.buy ? (
                      <Translation
                        k={`Min: {minSum} {fiat} Max: {maxSum} {fiat}`}
                        replaceDictionary={{
                          minSum: rate.limits.minSum,
                          maxSum: rate.limits.maxSum,
                          fiat: rate.limits.currency,
                        }}
                      />
                    ) : (
                      <Translation
                        k={`Rate: {sumFiat} {fiat} = {sumCoin} {coin}. include fee Noronex`}
                        replaceDictionary={{
                          fiat: rate.to,
                          sumFiat: rate.rate.toFixed(2),
                          coin: rate.from,
                          sumCoin: '1',
                        }}
                      />
                    )}
                  </>
                ) : null} */}
                  </div>
                )}
              </div>
              <div className={styles.calculator__card}>
                <div className={styles.calculator__cardHeader}>
                  <div className={styles.calculator__cardTitle}>
                    <Translation k={`calculator:output:label`} />
                  </div>

                  <div className={styles.calculator__cardLabel}>
                    <Translation
                      k={`Available: {sum} {coin}`}
                      replaceDictionary={{
                        coin: crypto['to'],
                        sum: balance,
                      }}
                    />
                  </div>

                  {/* {selectedOperation !== Operations.buy ? (
                <PaymentDropdown
                  options={paymentMethods.map((method) => ({
                    label: method,
                    value: method,
                  }))}
                  value={paymentMethod}
                  onSelect={(value) =>
                    dispatch(calculatorSlice.actions.setPaymentMethod(value))
                  }
                />
              ) : rate && !isFetching ? (
                <div className={styles.calculator__cardLabel}>
                  <Translation
                    k={`Available: {sum} {coin}`}
                    replaceDictionary={{
                      coin: rate.available.currency,
                      sum: rate.available.sum,
                    }}
                  />
                </div>
              ) : null} */}
                </div>
                <div className={styles.calculator__cardForm}>
                  <CalculatorDropdown
                    options={action === 'buy' ? currencyList : fiatList}
                    value={crypto['to']}
                    onSelect={(value) => onSelect('to', value)}
                  />

                  <div className={styles.calculator__cardValue}>
                    {calculate === 'to' ? (
                      <Loader narrow />
                    ) : (
                      <input
                        type="text"
                        // type={'number'}
                        // min={limit?.min}
                        // max={limit?.max}
                        // value={values['to']}
                        // onChange={(e) => onChange('to', e.target.value)}
                        {...register('to')}
                        className={styles.calculator__input}
                        tabIndex={1}
                        onKeyDown={handleKeyDown}
                      />
                    )}
                  </div>

                  {/* <div ref={resultRef} className={styles.calculator__cardValue}>
                {isFetching || !rate ? (
                  <Loader narrow />
                ) : (
                  <span ref={resultValueRef}>{getValue()}</span>
                )}
              </div> */}
                </div>
                <div className={styles.calculator__cardDescription}>
                  <Translation
                    k={`Rate: {sumFiat} {fiat} = {sumCoin} {coin}. include fee Noronex`}
                    replaceDictionary={{
                      fiat: crypto['from'],
                      sumFiat: course,
                      coin: crypto['to'],
                      sumCoin: '1',
                    }}
                  />
                  {/* {rate && !isFetching ? (
                <>
                  {selectedOperation !== Operations.buy ? (
                    <Translation
                      k={`Min: {minSum} {fiat} Max: {maxSum} {fiat}`}
                      replaceDictionary={{
                        minSum: rate.limits.minSum,
                        maxSum: rate.limits.maxSum,
                        fiat: rate.limits.currency,
                      }}
                    />
                  ) : (
                    <Translation
                      k={`Rate: {sumFiat} {fiat} = {sumCoin} {coin}. include fee Noronex`}
                      replaceDictionary={{
                        fiat: rate.from,
                        sumFiat: rate.rateForUnit.toFixed(2),
                        coin: rate.to,
                        sumCoin: '1',
                      }}
                    />
                  )}
                </>
              ) : null} */}
                </div>
              </div>
            </div>
            <div className={styles.calculator__action}>
              <BigButtonLink href={'https://client.noronex.com/'}>
                <svg width={29} height={28} xmlns="http://www.w3.org/2000/svg">
                  <use href="#transaction" />
                </svg>
                <span>
                  <Translation k={`calculator:start`} />
                </span>
              </BigButtonLink>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
