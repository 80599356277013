import {
  Section,
  SectionTitle,
} from "../../../components/layout/section";
import { Translation } from "../../../translations/translation";
import { FunctionComponent } from "react";
import styles from "./partnerSection.module.css";
import { Container } from "../../../components/layout/container";

export const PartnerSection: FunctionComponent = () => {
  return (
    <Section
      title={
        <SectionTitle>
          <Translation k={"Our partners"} />
        </SectionTitle>
      }
      description={
        <Translation
          k={
            "Our friends and partners"
          }
        />
      }
      shade
    >
      <Container>
        <div className={styles.partnerSection__row}>
          <img src={"/friends/bestchange.svg"} alt={""} />
          <img src={"/friends/crystal.svg"} alt={""} />
          <img src={"/friends/sumsub.svg"} alt={""} />
          <img src={"/friends/bitgo.svg"} alt={""} />
          <img src={"/friends/kraken.svg"} alt={""} />
        </div>
      </Container>
    </Section>
  );
};
