import { Container } from "../../components/layout/container";
import { Section } from "../../components/layout/section";
import { StepsBlock } from "./stepsBlock";
import { HomeBlock } from "./homeBlock";
import { FeaturesSection } from "./featuresSection";
import { LegalSection } from "./legalSection";
import { PricesSection } from "./pricesSection";
import {PartnerSection} from "./partnerSection";

export const Home = () => {
  return (
    <div>
      <Container>
        <HomeBlock />
      </Container>
      <FeaturesSection />
      <LegalSection />
      <PricesSection />
      <Section>
        <Container>
          <StepsBlock />
        </Container>
      </Section>
      <PartnerSection />
    </div>
  );
};
