import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Operations } from '../../../types/calculator';
import { CalculatorConfigType } from '../../api/calculator/types';

const initialState: {
  config: CalculatorConfigType | null;
  operation: Operations | null;
  inputCurrency: string | null;
  paymentMethod: string | null;
  outputCurrency: string | null;
  amount: string;
} = {
  config: null,
  operation: null,
  inputCurrency: null,
  paymentMethod: null,
  outputCurrency: null,
  amount: '100',
};

//check if value is in array if not return first element
const keepOrFirst = (arr: string[], value: string | null) => {
  if (!value) return arr[0];
  return arr.includes(value) ? value : arr[0];
};

export const calculatorSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setAmount: (state, action: PayloadAction<string>) => {
      state.amount = action.payload;
    },
    setConfig: (state, action: PayloadAction<CalculatorConfigType>) => {
      state.config = action.payload;
      state.operation = keepOrFirst(
        action.payload.operations,
        state.operation
      ) as Operations;
      const currencies = action.payload[state.operation];
      state.inputCurrency = keepOrFirst(
        Object.keys(currencies),
        state.inputCurrency
      );
      const paymentMethods = currencies[state.inputCurrency];
      state.paymentMethod = keepOrFirst(
        Object.keys(paymentMethods),
        state.paymentMethod
      );
      state.outputCurrency = keepOrFirst(
        paymentMethods[state.paymentMethod],
        state.outputCurrency
      );
    },
    setInputCurrency: (state, action: PayloadAction<string>) => {
      state.inputCurrency = action.payload;
      if (state.operation && state.config) {
        const currencies = state.config[state.operation];
        const paymentMethods = currencies[state.inputCurrency];
        state.paymentMethod = keepOrFirst(
          Object.keys(paymentMethods),
          state.paymentMethod
        );
        state.outputCurrency = keepOrFirst(
          paymentMethods[state.paymentMethod],
          state.outputCurrency
        );
      }
    },
    setOutputCurrency: (state, action: PayloadAction<string>) => {
      state.outputCurrency = action.payload;
    },
    setOperation: (state, action: PayloadAction<Operations>) => {
      state.operation = action.payload;
      if (state.config) {
        const currencies = state.config[state.operation];
        state.inputCurrency = keepOrFirst(
          Object.keys(currencies),
          state.inputCurrency
        );
        const paymentMethods = currencies[state.inputCurrency];
        state.paymentMethod = keepOrFirst(
          Object.keys(paymentMethods),
          state.paymentMethod
        );
        state.outputCurrency = keepOrFirst(
          paymentMethods[state.paymentMethod],
          state.outputCurrency
        );
      }
    },
    setPaymentMethod: (state, action: PayloadAction<string>) => {
      state.paymentMethod = action.payload;
      if (state.config && state.operation && state.inputCurrency) {
        const currencies = state.config[state.operation];
        const paymentMethods = currencies[state.inputCurrency];
        state.outputCurrency = keepOrFirst(
          paymentMethods[state.paymentMethod],
          state.outputCurrency
        );
      }
    },
  },
});
