import { AxiosResponse } from 'axios';

import { http } from '../../../utils/http';

import {
  ExchangeType,
  ICourse,
  IPaymentMethod,
  IPaymentMethodType,
  IRequestCalculation,
  IRequestExchange,
  IResponseCalculation,
  IResponseCourse,
  IResponseExchange,
  ITransformCourse,
} from './types';

class ExchangeService {
  async calculation(data: IRequestCalculation): Promise<IResponseCalculation> {
    const res = await http.post<
      IRequestCalculation,
      AxiosResponse<IResponseCalculation>
    >(`/calculation`, data);

    return res.data;
  }

  async exchange(data: IRequestExchange): Promise<IResponseExchange> {
    const res = await http.post<
      IRequestExchange,
      AxiosResponse<IResponseExchange>
    >(`/exchange`, data);

    if (res.data && res.data.info && res.data.info.requisites) {
      const obj: IResponseExchange = {
        ...res.data,
        info: {
          ...res.data.info,
          requisites: Object.values(res.data.info.requisites),
        },
      };

      return obj;
    }

    return res.data;
  }

  async createExchange(data: any): Promise<{ transaction_id: number }> {
    const res = await http.post<any>(`/exchange/create`, data);

    return res.data;
  }

  async getCourse(): Promise<ICourse> {
    const res = await http.get<ICourse, AxiosResponse<IResponseCourse>>(
      `/course`
    );

    const cryptocurrencies = res.data.cryptocurrencies.map((el, i) => {
      return {
        id: i + 1,
        label: el,
      };
    });

    const fiats = res.data.fiats.map((el, i) => {
      return {
        id: i + 1,
        label: el,
        symbol: el === 'USD' ? '$' : '€',
      };
    });

    const methods = Object.values(res.data.pay_methods).map((el, i) => {
      return {
        id: i + 1,
        label: el,
      };
    });

    const limits = Object.entries(res.data.limits).map((el, i) => {
      const currency = el[0];
      const label = currency.slice(0, 3);
      const limit = el[1];

      return { id: i, label, currency, min: +limit.min, max: +limit.max };
    });

    const payments = Object.entries(res.data.payments).map((el, i) => {
      const direction = el[0];
      const methods: IPaymentMethod[] = [];

      for (const [key, value] of Object.entries(el[1])) {
        methods.push({
          id: +key,
          type: value as IPaymentMethodType,
        });
      }

      return {
        id: i + 1,
        direction,
        methods,
      };
    });

    const types = res.data.types.map((el, i) => {
      return {
        id: i + 1,
        label: el,
      };
    });

    const courses: ITransformCourse[] = Object.entries(res.data.courses).map(
      (el) => {
        const method = el[0].toLocaleLowerCase() as ExchangeType;
        const courseList: any[] = [];

        for (const [key, value] of Object.entries(el[1])) {
          const array: any[] = [];
          for (const [k, v] of Object.entries(value)) {
            array.push({
              id: +k,
              course: v,
            });
          }

          courseList.push({
            direction: key,
            list: array,
          });
        }

        return {
          method,
          data: courseList,
        };
      }
    );

    const data = {
      courses,
      cryptocurrencies,
      fiats,
      balances: res.data.balances,
      limits,
      payMethods: methods,
      payments,
      types,
    };

    return data;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ExchangeService();
