import { api } from "../index";
import { ContactsResponseType } from "./types";

const contactsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getContacts: build.query<ContactsResponseType, { lang: string }>({
      query: ({ lang }) => `page/${lang}/contact`,
    }),
    sendMessage: build.mutation<
      {},
      { lang: string; email: string; topic: string; message: string }
    >({
      query: ({ lang, topic, email, message }) => ({
        url: `page/${lang}/contact`,
        method: "POST",
        body: { topic, email, message },
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetContactsQuery, useSendMessageMutation } = contactsApi;
