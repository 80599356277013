import { FunctionComponent, HTMLAttributes, useEffect, useState } from 'react';
import styles from './priceTable.module.css';
import { Translation } from '../../translations/translation';
import { Loader } from '../common/loader';
import { CourseRow } from './priceTableRow/CourseRow';
import axios from 'axios';
import { DayCourse, DayCourseItem } from 'store/api/prices/types';

export interface PriceTableProps extends HTMLAttributes<HTMLDivElement> {
  fiat: string;
  lang: string;
  main?: boolean;
}

export const PriceTable: FunctionComponent<PriceTableProps> = ({
  className,
  fiat,
  ...rest
}) => {
  const [table, setTable] = useState<DayCourse[]>([]);
  const [cources, setCources] = useState<DayCourseItem[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  // const { data, isFetching } = useGetPricesQuery({
  //   lang,
  //   fiat,
  //   main,
  // });

  useEffect(() => {
    loadCorces();
  }, []);

  useEffect(() => {
    if (table) {
      const el = table.find((el) => el.label === fiat);

      if (el) setCources(el.list);
    }
  }, [table, fiat]);

  const loadCorces = async () => {
    setLoading(true);
    try {
      const res = await axios.get<{ stats: { [key: string]: string } }>(`${process.env.REACT_APP_API_EXCHANGE}/day_course`);

      const cources = res.data && res.data.stats;

      if (cources) {
        const objectArray = Object.entries(cources);

        const arr = objectArray.map(([key, value], index) => {
          return {
            id: index,
            label: key,
            list: Object.values(value) as unknown as DayCourseItem[],
          };
        });

        setTable(arr);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // if (!data) {
  //   return <Loader />;
  // }

  return (
    <div className={`${styles.priceTable} ${className}`} {...rest}>
      {isLoading ? (
        <Loader />
      ) : (
        <table className={styles.priceTable__table}>
          <thead className={styles.priceTable__tableHead}>
            <tr className={styles.priceTable__tableHeadRow}>
              <th className={styles.priceTable__tableHeadCell} />
              <th className={styles.priceTable__tableHeadCell}>
                <Translation k={'Currency'} />
              </th>
              <th className={styles.priceTable__tableHeadCell}>
                <Translation k={'Price'} />
              </th>
              {/* <th className={styles.priceTable__tableHeadCell}>
                <Translation k={'24hr Change'} />
              </th> */}
              <th className={styles.priceTable__tableHeadCell}>
                <Translation k={'% Change'} />
              </th>
              <th className={styles.priceTable__tableHeadCell}>
                <Translation k={'Reserve'} />
              </th>
              {/* <th className={styles.priceTable__tableHeadCell} /> */}
            </tr>
          </thead>
          <tbody className={styles.priceTable__table__body}>
            {cources.map((row, index) => (
              <CourseRow key={index} row={row} fiat={fiat} />
            ))}
          </tbody>
        </table>
      )}
      <div className={styles.priceTable__footnote}>
        <p>
          <Translation
            k={'Displayed exchange rate including Noronex commission'}
          />
        </p>
      </div>
    </div>
  );
};
