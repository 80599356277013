import { FunctionComponent } from "react";
import { Section, SectionTitle } from "../../../components/layout/section";
import { Translation } from "../../../translations/translation";
import { CardGrid, CardGridItem } from "../../../components/layout/cardGrid";
import { FeatureCard } from "../../../components/cards/featureCard";
import coinIcon from "../../../images/icons/coin.svg";
import robotIcon from "../../../images/icons/robot.svg";
import cardIcon from "../../../images/icons/card.svg";
import graphIcon from "../../../images/icons/graph.svg";

export const FeaturesSection: FunctionComponent = () => {
  return (
    <Section
      title={
        <SectionTitle>
          <Translation k={"Our features"} />
        </SectionTitle>
      }
      description={
        <Translation
          k={
            "We constantly monitor the work of our service and try to make the interaction with our product a joy"
          }
        />
      }
      inverted
    >
      <CardGrid narrow>
        <CardGridItem>
          <FeatureCard
            title={<Translation k={"Immediate withdrawal"} />}
            icon={coinIcon}
          >
            <p>
              <Translation
                k={
                  "We do not store your cryptocurrency, it is sent immediately to the specified address"
                }
              />
            </p>
          </FeatureCard>
        </CardGridItem>
        <CardGridItem>
          <FeatureCard
            title={<Translation k={"Fast because automatic"} />}
            icon={robotIcon}
          >
            <p>
              <Translation
                k={"There is no need to wait for manual verification"}
              />
            </p>
          </FeatureCard>
        </CardGridItem>
        <CardGridItem>
          <FeatureCard
            title={<Translation k={"Multiple payment options"} />}
            icon={cardIcon}
          >
            <p>
              <Translation
                k={
                  "We accept debit and credit cards, SEPA bank transfers and multiple alternative payment methods"
                }
              />
            </p>
          </FeatureCard>
        </CardGridItem>
        <CardGridItem>
          <FeatureCard
            title={<Translation k={"We have the best exchange rate"} />}
            icon={graphIcon}
          >
            <p>
              <Translation
                k={"Every day we go out to make the bet even lower"}
              />
            </p>
          </FeatureCard>
        </CardGridItem>
      </CardGrid>
    </Section>
  );
};
